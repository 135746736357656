import React from "react";
import { graphql } from "gatsby";
import { Flex, Box } from "grid-styled";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import FlickrHero from "react-flickr-hero";

import { media } from "../utils/style";

import Layout from "../components/layout";
import NavBar from "../components/navbar";
import HeroText from "../components/heroText";
import SocialIcons from "../components/socialIcons";
import Portfolio from "../components/portfolio";
import Showcase from "../components/showcase";

const Content = styled.div`
  & > a {
    visibility: hidden;
    display: block;
    height: 0;
  }
  & > h1 {
    text-align: center;
  }
`;

const Title = styled.h1`
  font-family: "Raleway";
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  border: none;
  color: #292929;

  ${props =>
    props.small &&
    css`
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 700;
      line-height: 24px;
    `}
`;

const Section = styled.div`
  text-align: center;
  padding-top: 45px;
  padding-bottom: 40px;

  a {
    font-family: "Lato";
  }

  p {
    margin-bottom: 64px;
    font-size: large;
    color: #666;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway";
    text-transform: uppercase;
    color: #292929;
  }

  h4 {
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #292929;
  }

  span {
    color: #666;
    opacity: 0.5;
    display: block;
  }

  & > div:last-child {
    border-bottom: none !important;
  }

  ${props =>
    props.center &&
    css`
      text-align: left;
      & > * {
        margin-left: 30vw;
      }
      h4 {
        margin-left: 20vw;
      }

      ${media.xs`
        & > div {
          margin-left: 3vw !important;
        }
    `}
    `}

  ${props =>
    props.dark &&
    css`
      background: #292929;
      * {
        color: #eee;
      }
      span {
        text-align: left;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        opacity: 0.5;
      }
      span,
      p {
        color: #fefefe !important;
      }
      h6 {
        color: #fff;
        font-weight: 700;
      }
      h4 {
        color: #fff;
      }
      div {
        border-bottom: 1px solid #333 !important;
      }
    `}
`;

const Item = styled.div`
  width: 40%;
  margin: 0 auto;
  border: none;
  border-bottom: 1px solid #eee;
  h6 {
    letter-spacing: 2px;
    font-weight: 700;
    padding-top: 6px;
  }
  span,
  p {
    font-size: 13px;
    line-height: 24px;
    color: #666;
  }
  span {
    opacity: 0.75;
    float: right;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 24px;
    opacity: 0.5;
  }
  ${media.xs`
    width: 90%;

  `}
`;

export default props => {
  const content = (
    <Content>
      <FlickrHero
        api_key="1b4e5b0203fab0d5731afe68f0a543e1"
        user_id="132343752@N06"
        album_id="72157694825254121"
        fillPage
      />
      <HeroText />
      <SocialIcons
        style={{
          position: "absolute",
          margin: "0 auto",
          left: 0,
          right: 0,
          bottom: 16
        }}
        icons={[
          {
            name: "twitter",
            href: "https://twitter.com/PanaceaPiyush"
          },
          {
            name: "youtube",
            href: "https://www.youtube.com/channel/UCQR4tqiCqRfiPvKPw4o3TXg"
          },
          {
            name: "instagram",
            href: "https://www.instagram.com/discoveringpanacea/"
          },
          {
            name: "linkedin",
            href: "https://www.linkedin.com/in/piyushkhanduri/"
          }
        ]}
      />
      <a id="about-me">About Me</a>
      <Section>
        <Title>About Me</Title>
        <Flex alignItems="center" flexDirection="column">
          <Box px={2} width={[1, 1 / 2]}>
            <p>
            Hey! I am Piyush Khanduri. “I am a problem solver and absolutely hilarious at it.” Currently working for Publicis Groupe and helping their clients in digital media planning and executing. As far as my work goes I’ve probably worn every hat on the rack, most notable my marketing strategies and storytelling. 
            Don’t let my clean lines and weakness for Swiss type fool you. My work has been pretty diverse and enjoyable, to know more about my work and skills, follow me on my blogs and social media channels. Links above or bottom of the page.
            </p>
          </Box>
          
        </Flex>
      </Section>
      {/* <Title small>Portfolio</Title> */}
      {/* <a id="portfolio">Portfolio</a>
      <Portfolio items={props.data.allMarkdownRemark.edges} /> */}
      <a id="experience">Experience</a>
      <Section center dark>
        <h4>Experience</h4>
        <span>Where I've worked.</span>
        <Item>
          <span>NOV 2019 - CURRENT</span>
          <h6>Publicis Groupe</h6>
          <p>Media Planner</p>
        </Item>
        <Item>
          <span>SEPT 2018 - NOV 2019</span>
          <h6>Adapts Media</h6>
          <p>Social Media Specialist</p>
        </Item>
        <Item>
          <span>FEB 2017 - AUG 2018</span>
          <h6>KISAN (Krishi IS Acknowledged Now)</h6>
          <p>Founder</p>
        </Item>
        <Item>
          <span>JAN 2016 - JAN 2017</span>
          <h6>Whacky Ideas Zone</h6>
          <p>Marketing Executive</p>
        </Item>
      </Section>
      <a id="tech">Associations</a>
      <Section center>
        <h4>Associations</h4>
        <span>Brands and projects I have worked with</span>
        <Showcase
          images={
            props.data.allImageSharp ? props.data.allImageSharp.edges : []
          }
        />
      </Section>
      <a id="education">Education</a>
      <Section dark center>
        <h4>EDUCATION</h4>
        <span>Education I've recieved.</span>
        <Item>
          <span>2019 - 2021</span>
          <h6>M.A Psychology</h6>
          <p>IGNOU</p>
        </Item>
        <Item>
          <span>2013 - 2016</span>
          <h6>BCom Honours</h6>
          <p>GGSIP University</p>
        </Item>
        <Item>
          <span>2007-2012</span>
          <h6>SECONDARY EDUCATION</h6>
          <p>CBSE Board</p>
        </Item>
      </Section>
      <a id="tech">TECH</a>
      <Section center>
        <h4>TECH</h4>
        <span>Technologies I enjoy working with</span>
        <Showcase
          images={
            props.data.technologiesImageSharp
              ? props.data.technologiesImageSharp.edges
              : []
          }
        />
      </Section>
    </Content>
  );
  return (
    <Layout location={props.location}>
      <NavBar main children={content.props.children} />
      {content}
      
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    allImageSharp: allFile(filter: { relativePath: { regex: "/logos/" } }) {
      edges {
        node {
          id
          childImageSharp {
            sizes(maxWidth: 300, grayscale: true) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
    technologiesImageSharp: allFile(
      filter: { relativePath: { regex: "/tech/" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            sizes(maxWidth: 300) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
    allFile(filter: { name: { regex: "/signature/" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 200, grayscale: true) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
  }
`;
